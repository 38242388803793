const initBlock = async function( $block ) {
	const { Swiper } = await import( 'swiper' );
	const { Navigation, Pagination } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const $sliderWrap = $block.find( '.slider-services' );
	const $sliderMain = $sliderWrap.find( '.slider-services__main' );
	const $buttonPrev = $sliderWrap.find( '.slider-arrow--prev' );
	const $buttonNext = $sliderWrap.find( '.slider-arrow--next' );
	const $pagination = $sliderWrap.find( '.slider-nav__pagination' );

	let swiperServices = null;

	const debounce = function( func, wait ) {
		let timeout;
		return function( ...args ) {
			const later = () => {
				clearTimeout( timeout );
				func( ...args );
			};
			clearTimeout( timeout );
			timeout = setTimeout( later, wait );
		};
	};

	const handleResizeDebounced = debounce( function( $block ) {
		handleResize( $block );
	}, 250 );

	const handleResize = () => {
		destroySwiperServices();
		initSwiperServices();
	};

	const initSwiperServices = () => {
		if ( $sliderMain.length === 0 || $buttonNext.length === 0 || $buttonPrev.length === 0 || $pagination.length === 0 ) {
			console.warn( 'Swiper elements are not available for initialization.' );
			return;
		}

		const swiperServicesParams = {
			modules: [Navigation, Pagination],
			navigation: {
				nextEl: $buttonNext[0],
				prevEl: $buttonPrev[0]
			},
			pagination: {
				type: 'fraction',
				el: $pagination[0],
				renderFraction: function( currentClass, totalClass ) {
					return '<span class="' + currentClass + '"></span>' +
						' of ' +
						'<span class="' + totalClass + '"></span>';
				},
				formatFractionCurrent: function( number ) {
					return number.toString().padStart( 2, '0' );
				},
				formatFractionTotal: function( number ) {
					return number.toString().padStart( 2, '0' );
				}
			},
			init: false,
			loop: false,
			loopAdditionalSlides: 1,
			allowTouchMove: false,
			speed: 750,
			spaceBetween: 20,
			slidesPerView: 1,
			slidesPerGroup: 1,
			centeredSlides: true,
			breakpoints: {
				575: {
					loop: false,
					loopAdditionalSlides: 1,
					slidesPerView: 1,
					slidesPerGroup: 1,
				},
				768: {
					loop: false,
					loopAdditionalSlides: 0,
					slidesPerView: 2,
					slidesPerGroup: 2,
					centeredSlides: false,
				},
				1024: {
					loop: false,
					loopAdditionalSlides: 0,
					slidesPerView: 3,
					slidesPerGroup: 3,
					centeredSlides: false,
				}
			}
		};
		swiperServices = new Swiper( $sliderMain[0], swiperServicesParams );
		swiperServices.init();
	};

	const destroySwiperServices = () => {
		if ( swiperServices && typeof swiperServices.destroy === 'function' ) {
			swiperServices.destroy( true, true );
			swiperServices = null;
		} else {
			console.warn( 'SwiperServices is not initialized or already destroyed.' );
		}
	};

	handleResize();

	window.addEventListener( 'resize', () => handleResizeDebounced( $block ) );
};

themeUtils.loadBlock( initBlock, 'services', '.section-services' );
